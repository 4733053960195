// modelToServiceMap.js
const ModelToServiceMap = {
  'gpt-4o': 'openai',
  'gpt-4': 'openai',
  'gpt-4-1106-preview': 'openai',
  'gpt-3.5-turbo': 'openai',
  'claude-3-5-sonnet-20240620': 'anthropic',
  'claude-3-5-sonnet-20241022': 'anthropic'
};

export default ModelToServiceMap;
