import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

import { listOrganizationMembers } from '../Utilities/apiConnector';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StripedTableRow = styled(TableRow)(({ index }) =>
  index % 2 ? { background: '#f7f7f7' } : {}
);

function ListOrganizationUsers({ orgId }) {
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        // 1) Fetch all members of this organization
        const res = await listOrganizationMembers(orgId);
        if (res && res.data) {
          // The data is an array of membership objects:
          // [ { role, state, user: { id, email, displayName } }, ... ]
          setMembers(res.data);
        } else {
          setMembers([]);
        }
      } catch (error) {
        console.error('Error listing organization members:', error);
        setMembers([]);
      }
    };

    fetchMembers();
  }, [orgId]);

  // Suppose you want to navigate to some "edit user" page
  // You can also create an "edit membership" route if needed
  const handleEditUser = (userId) => {
    // Adjust to your actual route
    navigate(`/organizations/${orgId}/users/${userId}`);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>User</StyledTableCell>
            <StyledTableCell>Edit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.length > 0 ? (
            members.map((membership, index) => {
              // membership: { role, state, user: { id, email, displayName } }
              const { role, state, user } = membership;
              const { id: userId, email, displayName } = user;

              // Example: show role & state as a chip. 
              // If you only want role, remove state from the label.
              // Also, pick an icon color depending on state or role.
              let chipIcon = <PendingIcon style={{ color: 'orange' }} />;
              if (state === 'active' || state === 'accepted') {
                chipIcon = <CheckCircleIcon style={{ color: 'green' }} />;
              }

              return (
                <StripedTableRow key={userId} hover index={index}>
                  <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                        {email} 
                        {/* or use displayName if you prefer */}
                      </Typography>
                      <Chip
                        icon={chipIcon}
                        label={`${role} - ${state}`}
                      />
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      onClick={() => handleEditUser(userId)}
                      variant="text"
                      color="primary"
                    >
                      <NavigateNextIcon />
                    </Button>
                  </StyledTableCell>
                </StripedTableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                No members found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ListOrganizationUsers;
