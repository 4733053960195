import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Select, MenuItem, Button, InputLabel, FormControl, Typography, Container } from '@mui/material';
import { updateUserRole, getUser } from '../../Utilities/firestoreConnector';
import { removeOrganizationMember, resetUserPassword, getOrganization, updateOrganizationMember } from '../../Utilities/apiConnector';
import { ConfigContext } from '../../ConfigProvider';

function User() {
  const { userId, orgId } = useParams();
  const [ user, setUser ] = useState('');
  const [ role, setRole ] = useState('');
  const [org, setOrg] = useState();

  const { Roles } = useContext(ConfigContext);
  useEffect(() => { if (Roles) { setRole(Roles.Reader); } }, [Roles]);

  useEffect(() => {
    const fetchUser = async () => { setUser(await getUser(userId)); };
    fetchUser();
  }, [userId]);

  useEffect(() => {
    const GetOrganization = async () => {
      const organization = (await getOrganization(orgId)).data;
      setOrg(organization);
    }

    GetOrganization();
  }, [orgId]);

  const handleRoleChange = (event) => {
    event.preventDefault();
    setRole(event.target.value);
  };

  const handleResetPassword = async (event) => {
    const resp = await resetUserPassword(orgId, userId);
    console.log(resp);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Call updateOrganizationMember with the orgId, userId (memberId), and the updated role
      const response = await updateOrganizationMember(orgId, userId, { role });
      console.log('Member updated successfully:', response);
      // Optionally handle any UI updates or state changes here
    } catch (error) {
      console.error('Error updating member:', error);
      // Optionally display an error message in your UI
    }
  };

  const handleRemoveFromOrganization = async (event) => {
    event.preventDefault();
    await removeOrganizationMember(orgId, userId);
  };

  return (
    <Container>
      <Typography variant="h5" component="h2">User {user?.displayName}, {user?.email}</Typography>
      <Typography variant="h5" component="h2">{org?.name}</Typography>
      
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Select value={role} onChange={handleRoleChange}>
            {Roles && Object.keys(Roles).map((roleName) => ( <MenuItem key={roleName} value={Roles[roleName]}> {roleName} </MenuItem> ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">Save</Button>
        <Button type="submit" variant="contained" color="secondary" onClick={handleRemoveFromOrganization}>Remove From Organization</Button>
        <Button type="submit" variant="outlined" color="primary" onClick={handleResetPassword}>Reset Password</Button>
      </form>
    </Container>
  );
}

export default User;
