import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Typography, Box, Container
} from '@mui/material';
import { listModules, createModule, removeModule } from '../Utilities/apiConnector'; // Make sure these functions are defined correctly

export default function ModulesTable() {
  const { orgId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [modules, setModules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, [orgId]);

  const loadData = async () => {
    try {
      const data = await listModules(orgId);
      // Example response: [{id, name, owner, experts, summaryExpert}, ...]
      setModules(data);
    } catch (error) {
      console.error("Error loading modules:", error);
    }
  };

  const handleCreate = async () => {
    try {
      const newModule = {
        name: "New Module",
        owner: orgId,
        experts: [],
        summaryExpert: ""
      };
      const created = await createModule(orgId, newModule);
      console.log("created:",created)
      // Assuming `created` contains the newly created module including its ID
      navigate(`/organizations/${orgId}/modules/${created.data.id}`);
    } catch (error) {
      console.error('Error creating new module:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeModule(orgId, id);
      await loadData();
    } catch (error) {
      console.error('Error deleting module:', error);
    }
  };

  const handleEnter = (id) => {
    navigate(`/organizations/${orgId}/modules/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // If no search text, show all modules. If there's search text, filter by name.
  const filteredModules = searchText
    ? modules.filter(mod => mod.name.toLowerCase().includes(searchText.toLowerCase()))
    : modules;

  return (
    <TableContainer>
      <Paper>
        <Container>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 2, mb: 2 }}>
            <TextField
              variant="outlined"
              label="Search Modules"
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
              margin="normal"
              sx={{ mr: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
            >
              Create Module
            </Button>
          </Box>
        </Container>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Module</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Experts</TableCell>
              <TableCell>Summary Expert</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredModules?.data?.map((module, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="h6">{module.name}</Typography>
                  <Typography variant="body2">{module.id}</Typography>
                </TableCell>
                <TableCell>{module.owner}</TableCell>
                <TableCell>
                  {Array.isArray(module.experts) && module.experts.length > 0 
                    ? module.experts.join(', ') 
                    : '---'}
                </TableCell>
                <TableCell>{module.summaryExpert || '---'}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEnter(module.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(module.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </TableContainer>
  );
}
