import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Button, Paper } from '@mui/material';
import { UserContext } from '../UserProvider';
import { createConversation } from '../Utilities/apiConnector';
import ModuleSelector from '../Components/ModuleSelector';
import ChatTable from '../Components/ChatTable';

export default function Chats() {
  const { user, userData, updateUser } = useContext(UserContext);
  const [selectedModule, setSelectedModule] = useState(null);
  const navigate = useNavigate();
  const [selectedModuleId, setSelectedModuleId] = useState('');

  const handleCreateChat = async () => {
    if (!selectedModuleId) {
      alert('Please select a module before creating a chat.');
      return;
    }
  
    try {
      const newConversation = await createConversation(
        userData?.currentOrganization,
        {
        owner: user.uid,
        module: selectedModuleId,
      });
      if (newConversation && newConversation.data.id) {
        navigate(`/chats/${newConversation.data.id}`);
      } else {
        alert('Failed to create a new chat.');
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      alert('An error occurred while creating the chat. Please try again.');
    }
  };

  const handleModuleSelect = (moduleId) => {
    setSelectedModule(moduleId);
  };

  return (
    <Container component={Paper} style={{ padding: '20px' }}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={10}>
          <ModuleSelector orgId={userData?.currentOrganization} onSelect={setSelectedModuleId} />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateChat}
            fullWidth
          >
            Create Chat
          </Button>
        </Grid>
      </Grid>
      <ChatTable selectedModuleId={selectedModuleId} />
    </Container>
  );
}