import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, ListItem, ListItemText, Switch, FormControlLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { collection, query, where, getDocs, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { deleteVectors, deleteFileData, getResources } from '../Utilities/apiConnector';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CircularProgress from '@mui/material/CircularProgress';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { green, red, blue, grey } from '@mui/material/colors';
import { processFile, getResource, purgeFiles, deleteFile } from '../Utilities/apiConnector';
import { deleteExpertResource, deleteVectors as deleteFirestoreVectors, updateFile as updateFirestoreFile, getFile as getFirestoreFile } from '../Utilities/firestoreConnector';
import FileUploader from '../Components/FileUploader';
import { UserContext } from '../UserProvider';

function FileList({ onSelectFiles, category, id, refreshKey }) {
  const { user, userData, updateUser } = useContext(UserContext);
  const { orgId } = useParams();

  const [files, setFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();
  /*VOVA UPDATE, Toggle for "Use RAW" */
  const [useRaw, setUseRaw] = useState(false);

  useEffect(() => {
    const unsubscribe = loadFiles();
    return () => unsubscribe();
  }, [category, id, refreshKey, selectedFiles]);

  /*VOVA UPDATE, Toggle for "Use RAW" */
  const handleToggleChange = (event) => {
    setUseRaw(event.target.checked);
  };

  const columns = [
    {
      field: 'fileName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <ListItem>
          <ListItemText
            primary={params.value}
            secondary={`ID: ${params.row.id}`}
          />
        </ListItem>
      ),
    },
    {
      field: 'fileRef',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        params.value ?
          <>
            <Button variant="contained" color="primary" size="small" onClick={(e) => { e.stopPropagation(); handleDownload(params.row); }}>Download</Button>
            <Button variant="contained" color="secondary" size="small" style={{ marginLeft: '10px' }} onClick={(e) => { e.stopPropagation(); handleEdit(params.row); }}>Edit</Button>
          </>
          :
          <>File doesn't exist</>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params) => {
        switch (params.value) {
          case 'new': return <FiberNewIcon style={{ color: blue[500] }} />;
          case 'pending': return <><CircularProgress size={20} /> <Button variant='contained' color='secondary' onClick={_ => handleCancel(params.id)}>Cancel</Button> </>;
          case 'ready': return <CheckCircleIcon style={{ color: green[500] }} />;
          case 'aborted': return <DoNotDisturbAltIcon style={{ color: grey[500] }} />;
          case 'error': return <ErrorOutlineIcon style={{ color: red[500] }} />;
          default: return null;
        }
      }
    },
    {
      field: 'externalRef',
      headerName: 'External References',
      flex: 0.5,
      renderCell: (params) => (JSON.stringify(params.value) || "-")
    },
  ];

  const handleDownload = async (file) => {
    const { data } = await getResource("file", file.id);

    if (data.fileURL) {
      const link = document.createElement('a');
      link.href = data.fileURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('File URL is missing');
    }
  }

  const handleEdit = (row) => { navigate(`/organizations/${orgId}/files/${row.id}`); };

  const loadFiles = () => {
    if (!id) {
      console.error('Undefined ID');
      return;
    }

    const fileCollection = collection(db, 'files');
    const fileQuery = query(fileCollection, where('owner', '==', id));

    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      const filePromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const storageRef = ref(storage, data.fileRef);
        let url = null;
        try { url = await getDownloadURL(storageRef); } catch (e) { console.log(e); }
        return { id: doc.id, ...data };
      });

      Promise.all(filePromises).then(fileList => {
        setFiles(fileList);
      });

    });

    // Clean up listener on unmount
    return unsubscribe;
  };

  const handleCancel = async (e) => {
    const f = await getFirestoreFile(e);
    await updateFirestoreFile(e, { ...f, status: 'aborted' });
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedFiles.length === 0) {
      return;
    }
    const ids = selectedFiles.map(file => file.id);
    await deleteFile(ids[0], userData.currentOrganization);
  };
  

  const handleProcessFiles = async () => {
    const promises = selectedFiles.map(async (file) => {
      if (file.status === 'vectorized') {
        await deleteFirestoreVectors(file.id);
      }
      return processFile(file, useRaw, userData.currentOrganization);
    });

    await Promise.all(promises);
    // !!!WE HAVE TO UPDATE ALL EXPERTS VDB VECTORS FOR ALL EXPERTS FILES!!!

    //
    setSelectedFiles([]);
  };


  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleSelectionChange = (newSelection) => {
    // Filter out the IDs of files with a 'pending' status
    const selectableIds = newSelection.filter((id) =>
      files.find((file) => file.id === id && file.status !== 'pending')
    );

    // Find the selected files based on the filtered IDs
    const selectedFiles = files.filter((file) => selectableIds.includes(file.id));

    setSelectedFiles(selectedFiles);
    onSelectFiles(selectedFiles); // Call the callback with selected files
  };

  return (
    <Paper>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" component="h1" sx={{ p: 2 }}>File List</Typography>
          {/* <FileUploader owner={userData?.currentOrganization} type={"file"} /> */}
          {selectedFiles.length > 0 && (
            <>
              <Button variant="contained" color="primary" startIcon={<RotateLeftIcon />} onClick={handleProcessFiles}>Process</Button>

              {/*VOVA UPDATE, Toggle for "Use RAW" */}
              <FormControlLabel
                control={
                  <Switch
                    checked={useRaw}
                    onChange={handleToggleChange}
                    name="useRaw"
                    color="primary"
                  />
                }
                label="No Compression"
              />

              <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDeleteClick}>Delete</Button>
            </>
          )}
        </Box>
        <Box sx={{ m: 2 }}>
          <DataGrid
            rows={files}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionChange}
            rowSelectionModel={selectedFiles.map(file => file.id)}
            getRowId={(row) => row.id}
            isRowSelectable={(params) => params.row.status !== 'pending'}
            disableSelectionOnClick
          />
        </Box>
        <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
          <DialogTitle>Delete File</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete these {selectedFiles.length} file(s)?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
}

export default FileList;
