import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { Box, Button, Grid, TextField, Container, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import ChatBubble from '../Components/ChatBubble';
import ChatInput from '../Components/ChatInput';
import {
  runConversation,
  getConversationMessages,
  getConversation,
  addConversationMessages,
  clearConversationMessages,
  removeConversation,
  getModule,
  getExpert
} from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';
import FloatingWidget from '../Components/FloatingWidget';

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '85vh',
  padding: '16px',
});

const MessageContainer = styled(Box)({
  flex: '1 1 auto',
  overflowY: 'scroll',
  marginBottom: '16px',
});

export default function Chat() {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { chatId, moduleId } = useParams();

  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [chatName, setChatName] = useState('');
  const [textStream, setTextStream] = useState('');
  const [thinking, setThinking] = useState(false);

  const [floatingWidgetExperts, setFloatingWidgetExperts] = useState([]);
  const [expertIdInput, setExpertIdInput] = useState('');

  const [errorMessages, setErrorMessages] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    loadConversation();
    loadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const showError = (message) => {
    setErrorMessages((prev) => [...prev, { id: Date.now(), message }]);
  };
  
  const handleCloseError = (id) => {
    setErrorMessages((prev) => prev.filter((msg) => msg.id !== id));
  };

  const loadConversation = async () => {
    try {
      const response = await getConversation(userData.currentOrganization, chatId);
      setConversation(response.data);
      if (response.data && response.data.name) {
        setChatName(response.data.name);
        // Fetch module details to populate experts
        const moduleResponse = await getModule(userData.currentOrganization, response.data.module);
        if (moduleResponse?.data) {
          console.log(moduleResponse.data.experts);
          const experts = moduleResponse.data.experts || [];
          
          // Include summaryExpert if it exists
          if (moduleResponse.data.summaryExpert) {
            experts.push(moduleResponse.data.summaryExpert);
          }

          if (experts.length > 0) {
              // Fetch expert names and send to the FloatingWidget
              const fetchExpertDetails = async () => {
                  const expertDetails = await Promise.all(
                      experts.map(async (expert) => {
                          const response = await getExpert(userData.currentOrganization, expert);
                          const expertName = expert === moduleResponse.data.summaryExpert 
                              ? `${response.data.name} (Summary)` 
                              : response.data.name;
                          return { expertName, expertId: response.data.id };
                      })
                  );
          
                  // Send the expert details to the FloatingWidget
                  setFloatingWidgetExperts(expertDetails);
          
                  // Set current expert to the first in the list
                  setExpertIdInput(expertDetails[0].expertId);
                  console.log("setExpertIdInput(expertDetails[0]);", expertDetails[0].expertId);
              };
          
              fetchExpertDetails().catch((err) => {
                  // REPLACED console.error WITH NOTIFICATION
                  showError("Error fetching expert details: " + err);
              });
          }
        }
      }
    } catch (error) {
      // REPLACED console.error WITH NOTIFICATION
      showError("Failed to load conversation: " + error);
    }
  };

  const loadMessages = async () => {
    try {
      const response = await getConversationMessages(userData.currentOrganization, chatId);
      setMessages(response.data);
    } catch (error) {
      // REPLACED console.error WITH NOTIFICATION
      showError("Failed to load messages: " + error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, textStream]);

  const handleClear = async () => {
    if (!conversation) return;
    try {
      await clearConversationMessages(userData.currentOrganization, chatId);
      await loadMessages();
    } catch (error) {
      // REPLACED console.error WITH NOTIFICATION
      showError("Failed to clear conversation: " + error);
    }
  };

  const handleDelete = async () => {
    if (!conversation) return;
    try {
      await removeConversation(userData.currentOrganization, chatId);
      console.log('Conversation deleted.');
      navigate('/chats');
    } catch (error) {
      // REPLACED console.error WITH NOTIFICATION
      showError("Failed to delete conversation: " + error);
    }
  };

  const sendMessage = async (message) => {
    if (!conversation) {
      // REPLACED console.error WITH NOTIFICATION
      showError("Conversation not loaded yet.");
      return;
    }

    setThinking(true);

    const userMessageId = Date.now();
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: userMessageId, message, isUser: true, isLoading: true }
    ]);

    const botMessageId = Date.now() + 1;
    try {
      let streamedMessage = '';
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: botMessageId, message: streamedMessage, isUser: false, isLoading: true }
      ]);

      await runConversation(
        userData.currentOrganization,
        chatId,
        { message, expertid: expertIdInput },
        (chunk) => {
          streamedMessage += chunk;
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === botMessageId ? { ...msg, message: streamedMessage } : msg
            )
          );
        },
        async (error) => {
          if (error) {
            // REPLACED console.error WITH NOTIFICATION
            showError("Streaming error: " + error);
            setMessages((prevMessages) =>
              prevMessages.filter((msg) => msg.id !== botMessageId)
            );
            setTextStream('');
            setThinking(false);
            return;
          }

          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === botMessageId ? { ...msg, isLoading: false } : msg
            )
          );
          setTextStream('');
          setThinking(false);

          try {
            await addConversationMessages(userData.currentOrganization, chatId, {
              conversation: chatId,
              owner: userData.id,
              isUser: true,
              message,
              timestamp: Date.now(),
            });
            await addConversationMessages(userData.currentOrganization, chatId, {
              conversation: chatId,
              owner: userData.id,
              isUser: false,
              message: streamedMessage,
              timestamp: Date.now(),
            });
          } catch (addError) {
            // REPLACED console.error WITH NOTIFICATION
            showError("Error adding conversation message to the server: " + addError);
          }
        }
      );
    } catch (error) {
      // REPLACED console.error WITH NOTIFICATION
      showError("Error sending message: " + error);
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== botMessageId));
      setTextStream('');
      setThinking(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={3} style={{ paddingTop: '20px' }}>
        <Grid item xs={12}>
          <TextField
            label="Chat Name"
            fullWidth
            value={chatName}
            onChange={(e) => setChatName(e.target.value)}
          />
          <Box sx={{ mt: 1 }}>
            <Button variant="outlined" onClick={handleClear} sx={{ mr: 1 }}>
              Clear
            </Button>
            <Button variant="outlined" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <MessageContainer>
            {messages.map((msg) => (
              <ChatBubble
                key={msg.id}
                message={msg.message}
                isUser={msg.isUser}
              />
            ))}
            {textStream && <ChatBubble message={textStream} isUser={false} />}
            <div ref={messagesEndRef} />
          </MessageContainer>
        </Grid>

        <Grid item xs={12}>
          <ChatInput sendMessage={sendMessage} loading={thinking} />
        </Grid>
      </Grid>

      <FloatingWidget 
        setExpertIdInput={setExpertIdInput} 
        experts={floatingWidgetExperts}
      />
      {errorMessages.map(({ id, message }) => (
          <Snackbar key={id} open autoHideDuration={30000} onClose={() => handleCloseError(id)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={() => handleCloseError(id)} severity="error">{message}</Alert>
          </Snackbar>
        ))}
    </Container>
  );
}