import axios from 'axios';
import { auth } from '../firebase';
import { eventManager } from './eventManager';

// COMMON
const DEV_MODE = process.env.NODE_ENV === 'development';
const DEV = DEV_MODE;

// ENDPOINTS
const DEV_BASE = "http://127.0.0.1:5001/dev-immersion-ai-assistant/europe-west1/v3";
const PROD_BASE = "https://dev.api.aibs.immersionlabs.io";
const TARGET_BASE = DEV ? DEV_BASE : PROD_BASE;
const API_VERSION = "v3"

const ENDPOINT_CORE = `/core`;
const ENDPOINT_ORGANIZATIONS = `/organizations`;
const ENDPOINT_OPERATIONS = `/operations`;
const ENDPOINT_OPERATIONS_FILE = `/files`;
const ENDPOINT_OPERATIONS_TAG = `/operations/tag`;
const ENDPOINT_OPERATIONS_VECTORS = `/operations/vectors`;
const ENDPOINT_OPERATIONS_OBJECT = `/operations/object`;
const ENDPOINT_COMPLETIONS_CHATGPT = `/completions/chatgpt`;
const ENDPOINT_TTS_OPENAI = `/tts/openai`;
const ENDPOINT_STT_OPENAI = `/stt/openai`;
const ENDPOINT_OPERATIONS_API = `/operations/api`;
const ENDPOINT_CONVERSATIONS_API = `/conversations`;
const ENDPOINT_THREAD_API = `/threads`;
const ENDPOINT_USER = `/users`;
const ENDPOINT_MODULES = `/modules`;

// const SAVE_TO_PINECONE = "https://dev-26bcfd9.svc.us-west1-gcp-free.pinecone.io/vectors/upsert";

//=============================================================================================
//  COMMON
//=============================================================================================

const apiClient = axios.create({ baseURL: `${TARGET_BASE}/${API_VERSION}`, });

apiClient.interceptors.request.use(
  async (config) => {
    return config;
  }, (e) => { return Promise.reject(e); }
);

apiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const req = error.config;

    // token refresh if 401, catch generc errors
    if (error.response.status === 401 && !req._retry) {
      req._retry = true;
      console.log("Refreshing token...");
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken(true);
        setDefaultHeaders(token);
        req.headers['Authorization'] = `Bearer ${token}`;
        return apiClient(req);
      }
    } else if (!error.response || error.response.status >= 400) { eventManager.publish('api-error', error); }

    return Promise.reject(error);
  }
);

let userJWTToken = null;
export const setUserApiKey = (key) => {
  userJWTToken = key;
}

export const setDefaultHeaders = (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    'API-Auth': userJWTToken
  }
  apiClient.defaults.headers.common = { ...apiClient.defaults.headers.common, ...headers };
};

//=============================================================================================
//  USER EDITING
//=============================================================================================

export const addUserToOrganization = async (orgId, email, role) => await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/user`, { email, role }).catch(e => e);
export const removeUserFromOrganization = async (orgId, userId) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/user/${userId}`).catch(e => e);
export const resetUserPassword = async (orgId, userId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/user/${userId}/resetpassword`).catch(e => e);

//=============================================================================================
//  VECTORIZATION
//=============================================================================================

export const processFile = async (file, useRaw, orgId) => await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_OPERATIONS_FILE}/${file.id}/embed`, { useRaw: useRaw }).catch(e => e);
export const processTag = async (tag, useRaw) => await apiClient.post(`${ENDPOINT_OPERATIONS_TAG}/vectorize`, { tag }).catch(e => e);
export const addVectors = async (owner, vdb, namespace) => await apiClient.post(`${ENDPOINT_OPERATIONS_VECTORS}`, { owner, vdb, namespace }).catch(e => e);
export const deleteVectors = async (ownerid, vdb, namespace) => await apiClient.delete(`${ENDPOINT_OPERATIONS_VECTORS}/${ownerid}/${vdb}/${namespace}`).catch(e => e);
export const deleteFileData = async (fileId, vdb, namespace) => await apiClient.delete(`${ENDPOINT_OPERATIONS_FILE}/${fileId}`).catch(e => e);
export const deleteNamespace = async (vdb, namespace) => await apiClient.delete(`${ENDPOINT_OPERATIONS_VECTORS}/${vdb}/${namespace}`).catch(e => e);

//=============================================================================================
//  CONVERSATIONS
//=============================================================================================

// THIS IS PREV STREAMING VERSION WHICH CAUSED ISSUE< COMMENTED FOR NOW
// export const runConversation = async (  orgId,  id,  data,  onChunk,  onStreamEndCallback) => {
//   try {
//     const bearerToken = apiClient.defaults.headers.common['Authorization'];
//     const url = `${apiClient.defaults.baseURL}${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/run`;//?message=${data.message}&expertid=${data.expertid}  for GET

//     // Use native fetch with the SSE-friendly headers
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'text/event-stream',
//         // Add Authorization header if we have a token
//         ...(bearerToken ? { Authorization: bearerToken } : {}),
//       }
//       //body: JSON.stringify(data),
//     });

//     if (!response.ok) {
//       throw new Error(`Request failed with status ${response.status}`);
//     }

//     // If the environment does not support ReadableStream, throw
//     if (!response.body || typeof response.body.getReader !== 'function') {
//       throw new Error('ReadableStream not supported in this environment.');
//     }

//     const reader = response.body.getReader();
//     const decoder = new TextDecoder('utf-8');
//     let done = false;

//     // Read streamed chunks in a loop
//     while (!done) {
//       const { value, done: readerDone } = await reader.read();
//       console.log("reader:",reader)
//       done = readerDone;
      
//       if (value) {
//         console.log("value:",value)
//         var chunk = decoder.decode(value, { stream: true });
//         console.log("chunk:",chunk)
//         chunk = chunk
//               .replaceAll('data: ', '')  // Remove 'data: '
//               .replace(/\n\s*\n/g, '\n')  // Remove multiple empty lines
//               .replace(/\n$/, '')         // Trim trailing newline
//               //.replace(/\n+/, '')         // Trim trailing newline

//           // Ignore summary JSON chunks
//           if (chunk.includes('"usage":') || chunk.includes('"log":')) {
//               console.log('Ignoring summary chunk');
//               continue;
//           }

//           onChunk(chunk);
//       }
//     }

//     // Callback after finishing or upon error
//     if (onStreamEndCallback) {
//       console.log('onStreamEndCallback');
//       onStreamEndCallback();
//     }
//   } catch (error) {
//     console.error('Error in runConversation:', error);
//     if (onStreamEndCallback) {
//       onStreamEndCallback(error);
//     }
//     throw error; // Rethrow for upstream handling
//   }
// };

export const runConversation = async (
  orgId,
  id,
  data,
  onChunk,
  onStreamEndCallback
) => {
  try {
    const bearerToken = apiClient.defaults.headers.common['Authorization'];
    const url = `${apiClient.defaults.baseURL}${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/run`;

    // Make a standard POST request (no streaming)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add Authorization header if we have a token
        ...(bearerToken ? { Authorization: bearerToken } : {}),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    // Get the entire response as text
    const responseText = await response.text();

    // Split by newline and process each line
    const lines = responseText.split('\n').map(line => line.trim());

    for (let line of lines) {
      // Skip empty lines
      if (!line) continue;

      // Remove the 'data: ' prefix if present
      let chunk = line.replace(/^data:\s?/, '');

      // Ignore summary JSON chunks
      if (chunk.includes('"usage":') || chunk.includes('"log":')) {
        console.log('Ignoring summary chunk');
        continue;
      }

      // Pass chunk to the onChunk callback
      onChunk(chunk);
    }

    // Callback after finishing
    if (onStreamEndCallback) {
      console.log('onStreamEndCallback');
      onStreamEndCallback();
    }
  } catch (error) {
    console.error('Error in runConversationNonStream:', error);
    if (onStreamEndCallback) {
      onStreamEndCallback(error);
    }
    throw error; // Rethrow for upstream handling
  }
};

export const getConversationMessages = async (orgId, id) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/messages`).catch(e => e);
export const clearConversationMessages = async (orgId, id) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/messages`).catch(e => e);
export const addConversationMessages = async (orgId, id, messagesPayload) => {
  try {
    const url = `${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}/messages`;
    //console.log("messagesPayload:", messagesPayload)
    const response = await apiClient.post(url, messagesPayload);
    return response.data;
  } catch (error) {
    console.error("Error adding conversation message:", error);
    throw new Error("Failed to add conversation message");
  }
};

export const createThread = async (data) => await apiClient.post(`${ENDPOINT_THREAD_API}`, data).catch(e => e);
export const getConversations = async (orgId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}`).catch(e => e);
export const getConversationsByModule = async (orgId, id) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/modules/${id}${ENDPOINT_CONVERSATIONS_API}`).catch(e => e);
export const getConversation = async (orgId, id) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}`).catch(e => e);
export const createConversation = async (orgId, conversationData) => await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}`, conversationData).catch(e => e);
export const removeConversation = async (orgId, id) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_CONVERSATIONS_API}/${id}`).catch(e => e);

//=============================================================================================
//  MODULES
//=============================================================================================

export const listModules = async (orgId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}`).catch(e => e);
export const createModule = async (orgId, moduleData) => await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}`, moduleData).catch(e => e);
export const removeModule = async (orgId, moduleId) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}`).catch(e => e);
export const getModule = async (orgId, moduleId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}`).catch(e => e);
export const updateModule = async (orgId, moduleId, data) => await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_MODULES}/${moduleId}`, data).catch(e => e);

//=============================================================================================
//  OBJECTS
//=============================================================================================

// export const deleteObjectData = async (id) => await apiClient.delete(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);
export const getObject = async (id) => await apiClient.get(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);
export const deleteObject = async (id) => await apiClient.delete(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);

export const setTag = async (data) => await apiClient.post(`${ENDPOINT_OPERATIONS}/tag`, data).catch(e => e);
export const updateTag = async (id, data) => await apiClient.post(`${ENDPOINT_OPERATIONS}/tag/${id}`, data).catch(e => e);

// core resources this is dumb

export const getCoreResource = async (type, id) => await apiClient.get(`${ENDPOINT_CORE}/${type}/${id}`).catch(e => e);
export const setCoreResource = async (type, data) => await apiClient.post(`${ENDPOINT_CORE}/${type}`, { data }).catch(e => e);
export const updateCoreResource = async (type, data, id) => await apiClient.post(`${ENDPOINT_CORE}/${type}/${id}`, { data }).catch(e => e);
export const deleteResource = async (type, id) => await apiClient.delete(`${ENDPOINT_OPERATIONS}/${type}/${id}`).catch(e => e);

//=============================================================================================
//  EXPERTS
//=============================================================================================
export const addExpert = async (orgId, data) => await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts`, data).catch(e => e);
export const getExpert = async (orgId, id) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}`).catch(e => e);
export const getExperts = async (orgId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts`).catch(e => e);
export const updateExpert = async (orgId, data, id) => await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}`, data).catch(e => e);
export const removeExpert = async (orgId, id) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}`).catch(e => e);

//Body {  "fileid": "string" }
export const addExpertFile = async (orgId, data, id) => await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/file`, data).catch(e => e);
export const removeExpertFile = async (orgId, id, file_id) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/file/${file_id}`).catch(e => e);

//Body {  "finetuneid": "string" }
export const addExpertFinetune = async (orgId, data, id) => await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/finetune`, data).catch(e => e);
export const removeExpertFinetune = async (orgId, id, fintune_id) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}/experts/${id}/finetune/${fintune_id}`).catch(e => e);

// operations resources

export const getResource = async (type, id) => await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/${id}`).catch(e => e);
export const getResources = async (type, owner, filter = "") => await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/${owner}/list${filter}`).catch(e => e);
export const findResources = async (type, field, value = "") => await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/find-by/${field}/${value}`).catch(e => e);
export const getResourcesURI = async (uri) => await apiClient.get(`/${uri}`).catch(e => e);
export const setResource = async (type, data) => await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}`, { data }).catch(e => e);
export const updateResource = async (orgId, type, config, id) => await apiClient.put(`${ENDPOINT_ORGANIZATIONS}/${orgId}/${type}/${id}/config`, { config }).catch(e => e);

export const addStorageResource = async (file, owner, type, orgId) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('owner', owner);
  return await apiClient.post(`${ENDPOINT_ORGANIZATIONS}/${orgId}/${type}`, formData).catch(e => e);
};

// other

export const saveToOpenAI = async (type, id, data) => await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}/${id}/extref/openai`, { data }).catch(e => e);
export const deleteFromOpenAI = async (type, id) => await apiClient.delete(`${ENDPOINT_OPERATIONS}/${type}/${id}/extref/openai`).catch(e => e);

export const findConversations = async (orgId = "") => await apiClient.get(`/organizations/${orgId}/conversations`).catch(e => e); //`/conversations/find-by/${field}/${value}`

export const updateOrganization = async (data, id) => await apiClient.post(`/core/organization/${id}`, data).catch(e => e);

export const deleteFile = async (id, orgId) => await apiClient.delete(`${ENDPOINT_ORGANIZATIONS}/${orgId}${ENDPOINT_OPERATIONS_FILE}/${id}`).catch(e => e);
export const purgeFiles = async (ids) => await apiClient.delete(`${ENDPOINT_OPERATIONS}/file/batch/purge?files=${ids.join(',')}`).catch(e => e);

//=============================================================================================
//  FILES
//=============================================================================================
//RESPONSE
// [
//   {
//     "id": "string",
//     "owner": "string",
//     "fileName": "string",
//     "category": "string",
//     "type": "string",
//     "fileRef": "string",
//     "status": "string",
//     "fileURL": "string",
//     "metadata": {
//       "size": 0,
//       "contentType": "string"
//     }
//   }
// ]
export const getFiles = async (orgId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/files`).catch(e => e);


// RESPONSE
// [
//   {
//     "id": "string",
//     "name": "string",
//     "owner": "string",
//     "instructions": "string",
//     "role": "system",
//     "type": "instructions"
//   }
// ]
export const getFinetunes = async (orgId) => await apiClient.get(`${ENDPOINT_ORGANIZATIONS}/${orgId}/finetunes`).catch(e => e);



//=============================================================================================
//  CHAT
//=============================================================================================

export const getCompletionStream = async (input, expert, onMessageCallback, onStreamEndCallback) => {
  const url = new URL(`${TARGET_BASE}/${API_VERSION}${ENDPOINT_COMPLETIONS_CHATGPT}/stream`);
  url.searchParams.append('input', JSON.stringify(input));
  url.searchParams.append('expert', JSON.stringify(expert));
  url.searchParams.append('token', await auth.currentUser.getIdToken());

  const eventSource = new EventSource(url.toString());
  eventSource.onmessage = event => onMessageCallback(JSON.parse(event.data));
  eventSource.onerror = error => {
    console.error('EventSource failed:', error);
    eventSource.close();
    onStreamEndCallback();
  };

  return () => eventSource.close();
};

export const addMessageToConversation = async (id, message = '', isUser, data = {}) => await apiClient.post(`${ENDPOINT_CONVERSATIONS_API}/${id}/message`, { message, isUser, data }).catch(e => e);
export const addMessageToThread = async (id, data) => await apiClient.post(`${ENDPOINT_THREAD_API}/${id}`, data).catch(e => e);
export const clearMessages = async (id) => await apiClient.get(`${ENDPOINT_CONVERSATIONS_API}/${id}/clear`).catch(e => e);

export const sendMessageToConversation = async (id, message, onMessageCallback, onStreamEndCallback) => {
  const token = await auth.currentUser.getIdToken();

  const url = new URL(`${TARGET_BASE}/${API_VERSION}${ENDPOINT_CONVERSATIONS_API}/${id}/stream`);

  url.searchParams.append('message', JSON.stringify(message));
  url.searchParams.append('token', token);
  url.searchParams.append('apiauth', userJWTToken);

  const eventSource = new EventSource(url.toString());

  eventSource.onmessage = event => onMessageCallback(JSON.parse(event.data));
  eventSource.onerror = error => {
    console.error('EventSource failed:', error);
    eventSource.close();
    onStreamEndCallback();
  };

  return () => eventSource.close();
};


//=============================================================================================
//  TTS
//=============================================================================================

export const getSpeech = async (provider, data) => await apiClient.post(`/tts/${provider}`, data, {
  responseType: 'blob'
}).then(response => window.URL.createObjectURL(new Blob([response.data]))).catch(e => e);

//=============================================================================================
//  STT
//=============================================================================================

export const transcribeAudio = async (audio) => await apiClient.post(`${ENDPOINT_STT_OPENAI}/`, audio).catch(e => e);

//=============================================================================================
//  API
//=============================================================================================
export const setAppPassword = async (password, email = '') => await apiClient.post(`${ENDPOINT_USER}/${auth.currentUser.uid}/apppass`, { email: email ? email : auth.currentUser.email, password }).catch(e => e);
export const deleteAppPassword = async (id) => await apiClient.delete(`${ENDPOINT_USER}/${auth.currentUser.uid}/apppass`).catch(e => e);

export const getOpenAIKey = async (id) => await apiClient.get(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`).catch(e => e);
export const setOpenAIKey = async (id, key) => await apiClient.post(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`, { key }).catch(e => e);
export const deleteOpenAIKey = async (id) => await apiClient.delete(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`).catch(e => e);