import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, Container, Grid, TextField, Button, CircularProgress, Typography, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon, ListItemText, Checkbox, Radio
} from '@mui/material';
import { getModule, updateModule, getExperts } from '../Utilities/apiConnector'; // Adjust import paths as needed

export default function Module() {
  const { orgId, moduleId } = useParams();
  const [data, setData] = useState(null);
  const [editedData, setEditedData] = useState({
    name: '',
    experts: [],
    summaryExpert: ''
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [expertsList, setExpertsList] = useState([]); 
  const [expertDialogOpen, setExpertDialogOpen] = useState(false);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);

  useEffect(() => {
    loadModule();
  }, [orgId, moduleId]);

  useEffect(() => {
    loadExperts();
  }, [orgId]);

  const loadModule = async () => {
    try {
      setLoading(true);
      const moduleData = await getModule(orgId, moduleId);
      setData(moduleData.data);
      setEditedData({
        name: moduleData.data.name || '',
        experts: moduleData.data.experts || [],
        summaryExpert: moduleData.data.summaryExpert || ''
      });
    } catch (error) {
      console.error("Error loading module:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadExperts = async () => {
    try {
      const experts = await getExperts(orgId);
      setExpertsList(experts.data);
    } catch (error) {
      console.error('Error loading experts:', error);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const updated = {
        name: editedData.name,
        experts: editedData.experts, 
        summaryExpert: editedData.summaryExpert
      };
      await updateModule(orgId, moduleId, updated);
      await loadModule();
    } catch (error) {
      console.error('Error updating module:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field) => (event) => {
    setEditedData(prev => ({ ...prev, [field]: event.target.value }));
  };

  const handleOpenExpertDialog = () => {
    setExpertDialogOpen(true);
  };

  const handleCloseExpertDialog = () => {
    setExpertDialogOpen(false);
  };

  const handleOpenSummaryDialog = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };

  const toggleExpertSelection = (id) => {
    setEditedData((prev) => {
      const exists = prev.experts.includes(id);
      if (exists) {
        return { ...prev, experts: prev.experts.filter(e => e !== id) };
      } else {
        return { ...prev, experts: [...prev.experts, id] };
      }
    });
  };

  const selectSummaryExpert = (id) => {
    setEditedData((prev) => ({ ...prev, summaryExpert: id }));
  };

  if (loading) {
    return (
      <Container sx={{ p: 2 }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container sx={{ p: 2 }}>
        <Typography variant="h6">Module not found</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ p: 2 }}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <TextField
            value={editedData.name}
            onChange={handleChange('name')}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{ 
              style: { fontSize: '2rem' } 
            }}
          />
          <Typography variant="body2">ID: {data.id}</Typography>
          <Typography variant="body2">Owner: {data.owner}</Typography>
        </Grid>
      </Grid>

      {saving ? (
        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40vh', height: '5vh', m: 5, p: 1 }}>
            <Typography variant="h6" component="span" sx={{ mr: 2 }}>
              Saving...
            </Typography>
            <CircularProgress />
          </Paper>
        </Grid>
      ) : (
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {/* Select Experts */}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleOpenExpertDialog} sx={{ mb: 2 }}>
                Select Experts
              </Button>
              <Typography variant="body2">Selected Experts: </Typography>
              <Typography variant="body1">
                {editedData.experts.length === 0
                  ? 'No experts selected.'
                  : editedData.experts.map(eId => {
                      const expert = expertsList.find(exp => exp.id === eId);
                      return expert ? expert.name + ` (${expert.id})` : eId;
                    }).join(', ')
                }
              </Typography>
            </Grid>

            {/* Select Summary Expert */}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleOpenSummaryDialog} sx={{ mb: 2 }}>
                Select Summary Expert
              </Button>
              <Typography variant="body2">Summary Expert: </Typography>
              <Typography variant="body1">
                {editedData.summaryExpert
                  ? `${expertsList.find(exp => exp.id === editedData.summaryExpert)?.name || 'Unknown'} (${editedData.summaryExpert})`
                  : 'No summary expert selected.'
                }
              </Typography>
            </Grid>

            {/* Save Button */}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Dialog for Expert Selection */}
      <Dialog open={expertDialogOpen} onClose={handleCloseExpertDialog} fullWidth>
        <DialogTitle>Select Experts</DialogTitle>
        <DialogContent dividers>
          <List>
            {expertsList.map((expert) => {
              const selected = editedData.experts.includes(expert.id);
              return (
                <ListItem button key={expert.id} onClick={() => toggleExpertSelection(expert.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${expert.name} (${expert.id})`} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExpertDialog}>Done</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Summary Expert Selection */}
      <Dialog open={summaryDialogOpen} onClose={handleCloseSummaryDialog} fullWidth>
        <DialogTitle>Select Summary Expert</DialogTitle>
        <DialogContent dividers>
          <List>

            {/* Option to select NONE */}
            <ListItem button onClick={() => selectSummaryExpert('')}>
              <ListItemIcon>
                <Radio
                  edge="start"
                  checked={editedData.summaryExpert === ''}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary="None" />
            </ListItem>

            {expertsList.map((expert) => {
              const selected = editedData.summaryExpert === expert.id;
              return (
                <ListItem button key={expert.id} onClick={() => selectSummaryExpert(expert.id)}>
                  <ListItemIcon>
                    <Radio
                      edge="start"
                      checked={selected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${expert.name} (${expert.id})`} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSummaryDialog}>Done</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}