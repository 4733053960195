import React, { createContext } from 'react';

export const ConfigContext = createContext(null);

const Roles = {
  Reader: 'reader',
  Editor: 'editor',
  Owner: 'owner',
  Root: 'root',
};

const AccessList = {
  Owners: [Roles.Owner, Roles.Root],
  Editors: [Roles.Editor, Roles.Owner, Roles.Root],
  Everyone: [Roles.Reader, Roles.Editor, Roles.Owner, Roles.Root],
}

const ConfigProvider = ({ children }) => {


  return (
    <ConfigContext.Provider value={{ AccessList, Roles }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;