import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Container, Grid, Typography, Paper, CircularProgress } from '@mui/material';

// API connectors
import {
  addVectors,
  deleteNamespace,
  getExpert,
  updateExpert,
  addExpertFile,
  removeExpertFile,
  addExpertFinetune,
  removeExpertFinetune
} from '../Utilities/apiConnector';

import ResourceTable from '../Components/ResourceTable';
import ExpertConfig from '../Components/ExpertConfig';

import { eventManager } from '../Utilities/eventManager';
import { useError } from '../Components/contexts/ErrorContext';
import { useNotification } from '../Components/contexts/NotificationContext';

export default function Expert() {
  const { orgId, expertId } = useParams();
  const [data, setData] = useState({});
  const [objects, setObjects] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFinetunes, setSelectedFinetunes] = useState([]);
  const [saving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { showError, hasError } = useError();
  const { addNotification } = useNotification();

  useEffect(() => {
    // Subscribe to a global error handler
    const handleError = (error) => {
      showError(error);
    };
    eventManager.subscribe('api-error', handleError);
    return () => {
      eventManager.unsubscribe('api-error', handleError);
    };
  }, [showError]);

  useEffect(() => {
    const unsubscribe = loadExpert();
    return () => unsubscribe && unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const loadExpert = () => {
    if (!expertId) {
      console.error('Undefined expertId');
      return;
    }

    // Listen to changes on this expert doc
    const fileDocRef = doc(db, 'experts', expertId);
    const unsubscribe = onSnapshot(fileDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const dataFromDb = docSnapshot.data();
        setData(dataFromDb);

        // Store selection arrays
        setSelectedFiles(dataFromDb.resources?.files ?? []);
        setSelectedFinetunes(dataFromDb.resources?.finetunes ?? []);
      } else {
        console.error('Expert document does not exist');
      }
    });

    return unsubscribe;
  };

  /**
   * Handles the updated data object from ExpertConfig
   */
  const handleSave = async (newData) => {
    try {
      await updateExpert(orgId, newData, expertId);
      setRefresh(!refresh);
      addNotification(`Expert ${expertId} updated`, 'success');
    } catch (error) {
      showError(error);
    }
  };

  /**
   * Syncs new resource selection with Firestore and Vector DB,
   * for files, finetunes, or 3D objects.
   */
  const handleResourcesSelected = async ({ name, selection }) => {
    setSaving(true);

    try {
      const oldSelection = data.resources?.[name] ?? [];
      const newSelection = selection;

      // Determine which items got added or removed
      const added = newSelection.filter((item) => !oldSelection.includes(item));
      const removed = oldSelection.filter((item) => !newSelection.includes(item));

      if (name === 'files') {
        // For each newly selected file -> call addExpertFile
        for (const fileId of added) {
          await addExpertFile(orgId, { fileid: fileId }, expertId);
          if (hasError.current) break;
        }
        // For each file removed -> call removeExpertFile
        for (const fileId of removed) {
          await removeExpertFile(orgId, expertId, fileId);
          if (hasError.current) break;
        }
      } else if (name === 'finetunes') {
        // For each newly selected finetune -> call addExpertFinetune
        for (const ftId of added) {
          await addExpertFinetune(orgId, { finetuneid: ftId }, expertId);
          if (hasError.current) break;
        }
        // For each finetune removed -> call removeExpertFinetune
        for (const ftId of removed) {
          await removeExpertFinetune(orgId, expertId, ftId);
          if (hasError.current) break;
        }
      }

      // Once updates are done, re-fetch the expert
      const expertRes = await getExpert(orgId, expertId);
      const expert = expertRes.data;
      if (!expert || hasError.current) {
        setSaving(false);
        return;
      }

      // Gather updated references
      const allFiles = expert.resources?.files;
      // const allTagsArrays = await Promise.all(allTagsPromises);
      // const allTags = allTagsArrays.flat();
      // const allTagIds = allTags.map((tag) => tag.id);

      // If the expert doesn’t use external OpenAI references, update VDB
      if (!expert?.externalRef?.openai) {
        // For newly added finetunes, skip reindex if you don't want them in the VDB
        // else remove the condition.
        if (allFiles && name !== 'finetunes') {
          await updateVDB([...allFiles]);
        }
      }
    } catch (error) {
      showError(error);
    } finally {
      setSaving(false);
    }
  };

  /**
   * Updates vector DB with new content after clearing existing namespace
   */
  const updateVDB = async (selection) => {
    // await deleteNamespace(data.vdb ?? 'pinecone', expertId);
    // if (hasError.current) return;

    // // Add vectors for each file/tag in selection
    // await Promise.all(
    //   selection.map(async (item) => {
    //     await addVectors(item, data.vdb ?? 'pinecone', expertId);
    //   })
    // );
  };

  /**
   * Forces a "reload" for re-indexing all resources in Vector DB
   */
  const reloadExpert = async () => {
    setSaving(true);

    try {
      const expertRes = await getExpert(orgId, expertId);
      const expert = expertRes.data;
      if (!expert || hasError.current) {
        setSaving(false);
        return;
      }

      const allFiles = expert.resources?.files;
      if (allFiles) {
        await updateVDB([...allFiles]);
      }
    } catch (error) {
      showError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container sx={{ p: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ExpertConfig data={data} onUpdate={handleSave} onReload={reloadExpert} />
        </Grid>
      </Grid>

      {saving ? (
        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40vh',
              height: '5vh',
              m: 5,
              p: 1
            }}
          >
            <Typography variant="h6" component="span" sx={{ mr: 2 }}>
              Saving...
            </Typography>
            <CircularProgress />
          </Paper>
        </Grid>
      ) : (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* FILES */}
              <ResourceTable
                header="Files"
                onSelect={(selection) =>
                  handleResourcesSelected({ name: 'files', selection })
                }
                selected={selectedFiles}
                filter={data?.vdb === 'openai' ? ['externalRef.openai'] : []}
              />
            </Grid>
            <Grid item xs={12}>
              {/* FINETUNES */}
              <ResourceTable
                header="Finetunes"
                onSelect={(selection) =>
                  handleResourcesSelected({ name: 'finetunes', selection })
                }
                selected={selectedFinetunes}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                External Ref: {JSON.stringify(data?.externalRef)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
}
