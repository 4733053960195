// ExpertsTable.jsx
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Button, TextField, Typography, FormControl,
  FormHelperText, Select, MenuItem, CircularProgress, Grid,
  Box, Container, Checkbox, Dialog, DialogTitle, DialogContent,
  DialogActions
} from '@mui/material';

import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';

// Import the addExpert function
import { addExpert, removeExpert } from '../Utilities/apiConnector';
import ModelToServiceMap from '../Utilities/ModelToServiceMap';

export default function ExpertsTable() {
  const { orgId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [experts, setExperts] = useState([]);

  // Dialog state for creating a new Expert
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  // Form fields inside the dialog
  const [expertName, setExpertName] = useState('');
  const [selectedVdb, setSelectedVdb] = useState('pinecone');
  const [selectedModel, setSelectedModel] = useState('gpt-4o');

  const navigate = useNavigate();

  // Load existing experts from Firestore
  useEffect(() => {
    const unsubscribe = loadData();
    return () => unsubscribe();
  }, []);

  const loadData = () => {
    const expertsCollection = collection(db, 'experts');
    const fileQuery = query(expertsCollection, where('owner', '==', orgId));

    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      const filePromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        return { id: doc.id, ...data };
      });
      Promise.all(filePromises).then((list) => {
        setExperts(list);
      });
    });

    return unsubscribe;
  };

  // Open the Create Expert dialog
  const handleOpenCreateDialog = () => {
    // Reset form fields if needed
    setExpertName('');
    setSelectedVdb('pinecone');
    setSelectedModel('gpt-4o');
    setOpenCreateDialog(true);
  };

  // Close the Create Expert dialog
  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  // Actually create the expert via the API
  const handleConfirmCreateExpert = async () => {
    // Build the expert data shape
    const aiprovider = ModelToServiceMap[selectedModel] || 'openai'; // fallback
    const newExpertPayload = {
      name: expertName,
      owner: orgId,
      vdb: selectedVdb,
      config: {
        model: selectedModel,
        aiprovider,
        temp: 2,
        vdbTopK: 20,
        useHistory: true,
        prompt: {
          context: {
            role: 'user',
            enclosure: {
              start: '',
              end: ''
            }
          }
        },
        closingTag: '',
        commonDivider: ''
      }
    };

    try {
      // call API
      const resp = await addExpert(orgId, newExpertPayload);
      if (resp && resp.data && resp.data.id) {
        // On success, navigate to new expert's page
        navigate(`/organizations/${orgId}/experts/${resp.data.id}`);
      } else {
        console.error('Error: no ID returned from API', resp);
      }
    } catch (error) {
      console.error('Error creating expert:', error);
    } finally {
      // Close dialog
      handleCloseCreateDialog();
    }
  };

  // Delete an expert
  const handleDelete = async (id) => {
    try {
      const resp = await removeExpert(orgId, id);
      console.log('Deleted expert response:', resp);
      
      // Remove the expert from local state immediately after successful delete
      setExperts((prevExperts) => prevExperts.filter((expert) => expert.id !== id));
    } catch (error) {
      console.error('Error deleting expert:', error);
    }
  };

  // Edit (navigate)
  const handleEdit = (id) => {
    navigate(`/organizations/${orgId}/experts/${id}`);
  };

  // Search
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // Filter experts based on search text
  const filteredExperts = experts.filter((expert) =>
    expert.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      {/* MAIN TABLE UI */}
      <TableContainer>
        <Paper>
          <Container>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} sx={{ mr: 2 }}>
                <TextField
                  variant="outlined"
                  label="Search Experts"
                  value={searchText}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenCreateDialog}
                >
                  Create Expert
                </Button>
              </Box>
            </Box>
          </Container>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Expert</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExperts.map((expert, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="h6">
                      {expert.name}, {expert.vdb ?? "---"}
                    </Typography>
                    <Typography variant="body2">
                      {expert.id || ''}, {expert?.config?.model || 'gpt-4o'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(expert.id)}
                      style={{ marginRight: '10px' }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(expert.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>

      {/* DIALOG for Creating a new Expert */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create a new Expert</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextField
              label="Expert Name"
              value={expertName}
              onChange={(e) => setExpertName(e.target.value)}
              fullWidth
              margin="dense"
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <FormHelperText>VDB Selection</FormHelperText>
              <Select
                value={selectedVdb}
                onChange={(e) => setSelectedVdb(e.target.value)}
              >
                <MenuItem value="pinecone">pinecone</MenuItem>
                <MenuItem value="immersion">immersion</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <FormHelperText>Model Selection</FormHelperText>
              <Select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
              >
                {Object.keys(ModelToServiceMap).map((modelKey) => (
                  <MenuItem key={modelKey} value={modelKey}>
                    {modelKey}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="inherit">Cancel</Button>
          <Button onClick={handleConfirmCreateExpert} variant="contained" color="primary">
            CREATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
