import React, { useState, useEffect, useContext } from 'react';
import { Typography, TextField, Button, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { createUser, addMemberToOrganization, getOrganization } from '../Utilities/apiConnector'; 
import { ConfigContext } from '../ConfigProvider';

function AddUser({ orgId }) {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [org, setOrg] = useState(null);

  const { Roles } = useContext(ConfigContext);

  // Fetch org name (purely to display in the heading)
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await getOrganization(orgId);
        if (response && response.data) {
          setOrg(response.data);
        }
      } catch (error) {
        console.error('Error fetching organization:', error);
      }
    };
    fetchOrganization();
  }, [orgId]);

  // Default role
  useEffect(() => {
    if (Roles) {
      setRole(Roles.Reader);
    }
  }, [Roles]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let userEmail = email;
    // 1) Check if the email is already in use
    const checkRes = await createUser(email);

    if (checkRes && checkRes.status === 200) {
      userEmail = checkRes.data.email;
    } else if (checkRes && checkRes.response.data.message && checkRes.response.data.message.includes('already')) {
      console.log('Email already in use, skipping creation.');
    } else {
      throw new Error('Failed to create user');
    }
  
    // 2) Add the new user to this organization
    const addMemberRes = await addMemberToOrganization(orgId, {
      email:userEmail,
      role,
    });
    console.log(addMemberRes);
    if (!addMemberRes || addMemberRes.status !== 200) {
      throw new Error('Failed to add member to organization');
    }
  
    // Reset form
    setEmail('');
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto' }}>
      <Typography variant="subtitle1" gutterBottom>
        Add User to {org?.name}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          fullWidth
          required
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={(e) => setRole(e.target.value)}>
            {Roles && Object.keys(Roles).map((roleName) => (
              <MenuItem key={roleName} value={Roles[roleName]}>
                {roleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" type="submit" color="primary">
          Invite
        </Button>
      </form>
    </Box>
  );
}

export default AddUser;
