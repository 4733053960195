import React, { useRef, useState, useContext } from 'react';
import { Button, LinearProgress, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { auth } from '../firebase';
import { addStorageResource } from '../Utilities/apiConnector'
import { UserContext } from '../UserProvider';

export default function FileUploader({ type, owner }) {
  const fileInputRef = useRef(null);
  const [pending, setPending] = useState(false);
  const { user, userData } = useContext(UserContext);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i]);
    }
  };

  const uploadFile = async (file) => {
    if (!user) return; 
    console.log("userData:", userData)
    setPending(true);
    await addStorageResource(file, owner, type, userData.currentOrganization);
    setPending(false);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {!pending ? 
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleUploadClick}>Upload</Button>
          <input type="file" ref={fileInputRef} onChange={handleFileChange} multiple style={{ display: 'none' }} />
        </Grid> 
      :
        <Grid item xs>
          {pending && <CircularProgress />}
        </Grid>}
    </Grid>
  );
}