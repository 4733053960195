import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserProvider';
import { TextField, Container, Toolbar, Typography, Button, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { updateExpert } from '../Utilities/firestoreConnector';
import ModulesTable from '../Components/ModulesTable';

export default function Modules() {

  return (
      <ModulesTable />
    );
}