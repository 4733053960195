import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container } from '@mui/material';
import ListOrganizationUsers from '../../Components/ListOrganizationUsers';
import AddUser from '../../Components/AddUser';
import { getOrganization } from '../../Utilities/apiConnector';

function User() {
  const { orgId } = useParams();
  const [org, setOrg] = useState();

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await getOrganization(orgId);
        if (response && response.data) {
          setOrg(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch organization:', error);
      }
    };
    fetchOrganization();
  }, [orgId]);

  return (
    <>
      <Typography variant="h4" component="h1">
        {org?.name} Users
      </Typography>
      <Container>
        {/* Now uses the new membership-based list */}
        <ListOrganizationUsers orgId={orgId} />
      </Container>
      <AddUser orgId={orgId} />
    </>
  );
}

export default User;
