// MainLayout.js
import React, { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { AppBar, Toolbar, Typography, Button, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Grid, Container, CssBaseline } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth, db } from './firebase';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { UserContext } from './UserProvider';
import { ConfigContext } from './ConfigProvider';
import Sidebar from './Components/Sidebar'
import TopBar from './Components/TopBar';

function MainLayout({ children }) {
  const { user, userData, updateUser, loading } = useContext(UserContext);
  const { AccessList } = useContext(ConfigContext);
  const [organization, setOrganization] = useState('');
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  let currentOrgRole = '';

  useEffect(() => {
    if (user === null && !loading) { navigate('/login'); }

    const fetchData = async () => {
      if (userData){
        //FRONTEND ONLY
        if(userData.superUser == true){
          setUserRole("owner");
        }
      }

      if (userData && userData.currentOrganization) {

        const docRef = doc(db, "organizations", userData.currentOrganization);
        const docSnap = await getDoc(docRef);


        if (docSnap.exists()) {
          const organization = docSnap.data().name;
          setOrganization(organization);
        } else {
          setOrganization('Organization not found');
        }
      } else {
        setOrganization('N/A');
      }
    }

    fetchData();
  }, [userData, navigate, user, loading]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) { return null; }

  // if (userData &&
  //   userData.currentOrganization) {
  //   const currentOrg = userData.currentOrganization;
  //   console.log('currentOrg', userRole);
  //   if (currentOrg) currentOrgRole = currentOrg.role;
  // }

  return (
    <Container>
      <TopBar organization={organization} user={user} handleLogout={handleLogout} />
      <Grid container sx={{ mt: '64px' }}>
        <Grid item xs={2}>
          <Sidebar handleNavigation={handleNavigation} AccessList={AccessList} currentOrgRole={userRole} userData={userData} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MainLayout;