import React, { useContext } from 'react';
import { Typography, Button } from '@mui/material';
import { UserContext } from '../UserProvider';

function Dashboard() {
  const { user, userData } = useContext(UserContext);

  return (
    <>
      <Typography variant="h4">
        {user ? `Hello, ${userData?.displayName}!` : 'Not logged in'}
      </Typography>
    </>
  );
}

export default Dashboard;
