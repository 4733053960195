import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserProvider';
import { Navigate } from 'react-router-dom';
import { getUserRole } from './Utilities/apiConnector';

const ProtectedComponent = ({ children, roles }) => {
  const { userData } = useContext(UserContext);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRole = async () => {
      if (userData && userData.id) {
        try {
          //FRONTEND ONLY
          // const response = await getUserRole(userData.id);
          // setUserRole(response.data);
          if(userData.superUser == true){
            setUserRole("owner");
          }
        } catch (error) {
          console.error('Failed to fetch user role:', error);
          setUserRole(null);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchRole();
  }, [userData]);

  if (loading) {
    return null;
  }

  if (userData && userRole && roles.includes(userRole)) {
    return children;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default ProtectedComponent;