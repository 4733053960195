import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
} from '@mui/material';
import { listModules } from '../Utilities/apiConnector';

export default function ModuleSelector({ orgId, onSelect }) {
  const [modules, setModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchModules = async () => {
    try {
      const response = await listModules(orgId);
      setModules(response.data);
      setFilteredModules(response.data);
      setLoading(false);
      if (response.data?.length > 0) {
        const firstModuleId = response.data[0].id;
        setSelectedModule(firstModuleId);
        onSelect(firstModuleId);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  useEffect(() => {
    fetchModules();
  }, [orgId]);

  useEffect(() => {
    const filtered = modules.filter((module) =>
      module.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredModules(filtered);
  }, [searchTerm, modules]);

  const handleChange = (e) => {
    const selectedModuleId = e.target.value;
    setSelectedModule(selectedModuleId);
    onSelect(selectedModuleId);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FormControl fullWidth>
      <FormHelperText>Select Module</FormHelperText>
      <TextField
        placeholder="Filter Module Names..."
        value={searchTerm}
        onChange={handleSearchChange}
        variant="outlined"
        margin="dense"
        fullWidth
      />
      <Select value={selectedModule} onChange={handleChange} fullWidth>
        {filteredModules.length > 0 ? (
          filteredModules.map((module) => (
            <MenuItem key={module.id} value={module.id}>
              {module.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No modules found</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
