import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)({
  flex: 1,
  marginRight: '10px',
});

function ChatInput({ sendMessage, loading }) {
  const [message, setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (message.trim() !== '' && !loading) {
      sendMessage(message);
      setMessage('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Insert newline
        e.preventDefault();
        setMessage((prevMessage) => prevMessage + '\n');
      } else {
        // Submit form
        e.preventDefault();
        onSubmit(e);
      }
    }
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledTextField
        multiline
        minRows={1}
        maxRows={10}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type a message..."
        variant="outlined"
        InputProps={{
          style: {
            maxHeight: 400,
            overflow: 'auto',
          },
        }}
      />
      <Button variant="contained" color="primary" type="submit" disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
      </Button>
    </StyledForm>
  );
}

export default ChatInput;
